@font-face {
  font-family: EBI-Common;
  src: url("EBI-Common.7e0ca922.woff2") format("woff2"), url("EBI-Common.6da49a9f.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-Conceptual;
  src: url("EBI-Conceptual.d58e7ec0.woff2") format("woff2"), url("EBI-Conceptual.dfae09ce.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-Species;
  src: url("EBI-Species.5df084c9.woff2") format("woff2"), url("EBI-Species.9b906e30.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-FileFormats;
  src: url("EBI-FileFormats.b37e0eb7.woff2") format("woff2"), url("EBI-FileFormats.ffac20f3.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-Chemistry;
  src: url("EBI-Chemistry.aa205bb6.woff2") format("woff2"), url("EBI-Chemistry.b3e6016a.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-Functional;
  src: url("EBI-Functional.f6abde63.woff2") format("woff2"), url("EBI-Functional.9bf3b4c5.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-Generic;
  src: url("EBI-Generic.3a58104f.woff2") format("woff2"), url("EBI-Generic.d3bf28ca.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: EBI-SocialMedia;
  src: url("EBI-SocialMedia.70cd20b5.woff2") format("woff2"), url("EBI-SocialMedia.ee65237b.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.icon {
  background-size: contain;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  text-rendering: optimizeLegibility !important;
}

input.icon-common, .icon-common:before, input.icon-conceptual, .icon-conceptual:before, input.icon-functional, .icon-functional:before, input.icon-generic, .icon-generic:before, input.icon-species, .icon-species:before, input.icon-socialmedia, .icon-socialmedia:before, input.icon-fileformats, .icon-fileformats:before, input.icon-chemistry, .icon-chemistry:before {
  content: attr(data-icon);
  text-transform: none;
}

i.icon-common, input.icon-common, .icon-common:before {
  font-family: EBI-Common;
}

i.icon-conceptual, input.icon-conceptual, .icon-conceptual:before {
  font-family: EBI-Conceptual;
}

i.icon-functional, input.icon-functional, .icon-functional:before {
  font-family: EBI-Functional;
}

i.icon-generic, input.icon-generic, .icon-generic:before {
  font-family: EBI-Generic;
}

i.icon-species, input.icon-species, .icon-species:before {
  font-family: EBI-Species;
}

i.icon-socialmedia, input.icon-socialmedia, .icon-socialmedia:before {
  font-family: EBI-SocialMedia;
}

input.icon-fileformats, .icon-fileformats:before {
  font-family: EBI-FileFormats;
  font-size: 123.1%;
}

i.icon-chemistry, input.icon-chemistry, .icon-chemistry:before {
  font-family: EBI-Chemistry;
}

.icon.icon-spacer:before {
  padding-right: .5rem;
}

.icon.icon-chemistry.icon-direction_left:before {
  content: "l";
}

.icon.icon-chemistry.icon-direction_reversible:before {
  content: "R";
}

.icon.icon-chemistry.icon-direction_right:before {
  content: "r";
}

.icon.icon-chemistry.icon-direction_unknown:before {
  content: "U";
}

.icon.icon-common.icon-clock:before {
  content: "";
}

.icon.icon-common.icon-address-book:before {
  content: "";
}

.icon.icon-common.icon-address-card:before {
  content: "";
}

.icon.icon-common.icon-adjust:before {
  content: "";
}

.icon.icon-common.icon-align-center:before {
  content: "";
}

.icon.icon-common.icon-align-justify:before {
  content: "";
}

.icon.icon-common.icon-align-left:before {
  content: "";
}

.icon.icon-common.icon-align-right:before {
  content: "";
}

.icon.icon-common.icon-ambulance:before {
  content: "";
}

.icon.icon-common.icon-american-sign-language-interpreting:before {
  content: "";
}

.icon.icon-common.icon-anchor:before {
  content: "";
}

.icon.icon-common.icon-angle-double-down:before {
  content: "";
}

.icon.icon-common.icon-angle-double-left:before {
  content: "";
}

.icon.icon-common.icon-angle-double-right:before {
  content: "";
}

.icon.icon-common.icon-angle-double-up:before {
  content: "";
}

.icon.icon-common.icon-angle-down:before {
  content: "";
}

.icon.icon-common.icon-angle-left:before {
  content: "";
}

.icon.icon-common.icon-angle-right:before {
  content: "";
}

.icon.icon-common.icon-angle-up:before {
  content: "";
}

.icon.icon-common.icon-archive:before {
  content: "";
}

.icon.icon-common.icon-arrow-alt-circle-down:before {
  content: "";
}

.icon.icon-common.icon-arrow-alt-circle-left:before {
  content: "";
}

.icon.icon-common.icon-arrow-alt-circle-right:before {
  content: "";
}

.icon.icon-common.icon-arrow-alt-circle-up:before {
  content: "";
}

.icon.icon-common.icon-arrow-circle-down:before {
  content: "";
}

.icon.icon-common.icon-arrow-circle-left:before {
  content: "";
}

.icon.icon-common.icon-arrow-circle-right:before {
  content: "";
}

.icon.icon-common.icon-arrow-circle-up:before {
  content: "";
}

.icon.icon-common.icon-arrow-down:before {
  content: "";
}

.icon.icon-common.icon-arrow-left:before {
  content: "";
}

.icon.icon-common.icon-arrow-right:before {
  content: "";
}

.icon.icon-common.icon-arrow-up:before {
  content: "";
}

.icon.icon-common.icon-arrows-alt-h:before {
  content: "";
}

.icon.icon-common.icon-arrows-alt-v:before {
  content: "";
}

.icon.icon-common.icon-arrows-alt:before {
  content: "";
}

.icon.icon-common.icon-assistive-listening-systems:before {
  content: "";
}

.icon.icon-common.icon-asterisk:before {
  content: "";
}

.icon.icon-common.icon-at:before {
  content: "";
}

.icon.icon-common.icon-audio-description:before {
  content: "";
}

.icon.icon-common.icon-backward:before {
  content: "";
}

.icon.icon-common.icon-balance-scale:before {
  content: "";
}

.icon.icon-common.icon-ban:before {
  content: "";
}

.icon.icon-common.icon-barcode:before {
  content: "";
}

.icon.icon-common.icon-bars:before {
  content: "";
}

.icon.icon-common.icon-baseball-ball:before {
  content: "";
}

.icon.icon-common.icon-basketball-ball:before {
  content: "";
}

.icon.icon-common.icon-bath:before {
  content: "";
}

.icon.icon-common.icon-battery-empty:before {
  content: "";
}

.icon.icon-common.icon-battery-full:before {
  content: "";
}

.icon.icon-common.icon-battery-half:before {
  content: "";
}

.icon.icon-common.icon-battery-quarter:before {
  content: "";
}

.icon.icon-common.icon-battery-three-quarters:before {
  content: "";
}

.icon.icon-common.icon-bed:before {
  content: "";
}

.icon.icon-common.icon-beer:before {
  content: "";
}

.icon.icon-common.icon-bell-slash:before {
  content: "";
}

.icon.icon-common.icon-bell:before {
  content: "";
}

.icon.icon-common.icon-bicycle:before {
  content: "";
}

.icon.icon-common.icon-binoculars:before {
  content: "";
}

.icon.icon-common.icon-birthday-cake:before {
  content: "";
}

.icon.icon-common.icon-blind:before {
  content: "";
}

.icon.icon-common.icon-bold:before {
  content: "";
}

.icon.icon-common.icon-bolt:before {
  content: "";
}

.icon.icon-common.icon-bomb:before {
  content: "";
}

.icon.icon-common.icon-book:before {
  content: "";
}

.icon.icon-common.icon-bookmark:before {
  content: "";
}

.icon.icon-common.icon-bowling-ball:before {
  content: "";
}

.icon.icon-common.icon-braille:before {
  content: "";
}

.icon.icon-common.icon-briefcase:before {
  content: "";
}

.icon.icon-common.icon-bug:before {
  content: "";
}

.icon.icon-common.icon-building:before {
  content: "";
}

.icon.icon-common.icon-bullhorn:before {
  content: "";
}

.icon.icon-common.icon-bullseye:before {
  content: "";
}

.icon.icon-common.icon-calculator:before {
  content: "";
}

.icon.icon-common.icon-calendar-alt:before {
  content: "";
}

.icon.icon-common.icon-calendar-check:before {
  content: "";
}

.icon.icon-common.icon-calendar-minus:before {
  content: "";
}

.icon.icon-common.icon-calendar-plus:before {
  content: "";
}

.icon.icon-common.icon-calendar-times:before {
  content: "";
}

.icon.icon-common.icon-calendar:before {
  content: "";
}

.icon.icon-common.icon-caret-down:before {
  content: "";
}

.icon.icon-common.icon-caret-left:before {
  content: "";
}

.icon.icon-common.icon-caret-right:before {
  content: "";
}

.icon.icon-common.icon-caret-square-down:before {
  content: "";
}

.icon.icon-common.icon-caret-square-left:before {
  content: "";
}

.icon.icon-common.icon-caret-square-right:before {
  content: "";
}

.icon.icon-common.icon-caret-square-up:before {
  content: "";
}

.icon.icon-common.icon-caret-up:before {
  content: "";
}

.icon.icon-common.icon-cart-arrow-down:before {
  content: "";
}

.icon.icon-common.icon-cart-plus:before {
  content: "";
}

.icon.icon-common.icon-certificate:before {
  content: "";
}

.icon.icon-common.icon-chart-area:before {
  content: "";
}

.icon.icon-common.icon-chart-bar:before {
  content: "";
}

.icon.icon-common.icon-chart-line:before {
  content: "";
}

.icon.icon-common.icon-chart-pie:before {
  content: "";
}

.icon.icon-common.icon-check-circle:before {
  content: "";
}

.icon.icon-common.icon-check-square:before {
  content: "";
}

.icon.icon-common.icon-check:before {
  content: "";
}

.icon.icon-common.icon-chevron-circle-down:before {
  content: "";
}

.icon.icon-common.icon-chevron-circle-left:before {
  content: "";
}

.icon.icon-common.icon-chevron-circle-right:before {
  content: "";
}

.icon.icon-common.icon-chevron-circle-up:before {
  content: "";
}

.icon.icon-common.icon-chevron-down:before {
  content: "";
}

.icon.icon-common.icon-chevron-left:before {
  content: "";
}

.icon.icon-common.icon-chevron-right:before {
  content: "";
}

.icon.icon-common.icon-chevron-up:before {
  content: "";
}

.icon.icon-common.icon-child:before {
  content: "";
}

.icon.icon-common.icon-circle-notch:before {
  content: "";
}

.icon.icon-common.icon-circle:before {
  content: "";
}

.icon.icon-common.icon-clipboard:before {
  content: "";
}

.icon.icon-common.icon-clone:before {
  content: "";
}

.icon.icon-common.icon-closed-captioning:before {
  content: "";
}

.icon.icon-common.icon-cloud-download-alt:before {
  content: "";
}

.icon.icon-common.icon-cloud-upload-alt:before {
  content: "";
}

.icon.icon-common.icon-cloud:before {
  content: "";
}

.icon.icon-common.icon-code-branch:before {
  content: "";
}

.icon.icon-common.icon-code:before {
  content: "";
}

.icon.icon-common.icon-coffee:before {
  content: "";
}

.icon.icon-common.icon-cog:before {
  content: "";
}

.icon.icon-common.icon-cogs:before {
  content: "";
}

.icon.icon-common.icon-columns:before {
  content: "";
}

.icon.icon-common.icon-comment-alt:before {
  content: "";
}

.icon.icon-common.icon-comment:before {
  content: "";
}

.icon.icon-common.icon-comments:before {
  content: "";
}

.icon.icon-common.icon-compass:before {
  content: "";
}

.icon.icon-common.icon-compress:before {
  content: "";
}

.icon.icon-common.icon-copy:before {
  content: "";
}

.icon.icon-common.icon-copyright:before {
  content: "";
}

.icon.icon-common.icon-credit-card:before {
  content: "";
}

.icon.icon-common.icon-crop:before {
  content: "";
}

.icon.icon-common.icon-crosshairs:before {
  content: "";
}

.icon.icon-common.icon-cube:before {
  content: "";
}

.icon.icon-common.icon-cubes:before {
  content: "";
}

.icon.icon-common.icon-cut:before {
  content: "";
}

.icon.icon-common.icon-database:before {
  content: "";
}

.icon.icon-common.icon-deaf:before {
  content: "";
}

.icon.icon-common.icon-desktop:before {
  content: "";
}

.icon.icon-common.icon-dollar-sign:before {
  content: "";
}

.icon.icon-common.icon-dot-circle:before {
  content: "";
}

.icon.icon-common.icon-download:before {
  content: "";
}

.icon.icon-common.icon-edit:before {
  content: "";
}

.icon.icon-common.icon-eject:before {
  content: "";
}

.icon.icon-common.icon-ellipsis-h:before {
  content: "";
}

.icon.icon-common.icon-ellipsis-v:before {
  content: "";
}

.icon.icon-common.icon-envelope-open:before {
  content: "";
}

.icon.icon-common.icon-envelope-square:before {
  content: "";
}

.icon.icon-common.icon-envelope:before {
  content: "";
}

.icon.icon-common.icon-eraser:before {
  content: "";
}

.icon.icon-common.icon-euro-sign:before {
  content: "";
}

.icon.icon-common.icon-exchange-alt:before {
  content: "";
}

.icon.icon-common.icon-exclamation-circle:before {
  content: "";
}

.icon.icon-common.icon-exclamation-triangle:before {
  content: "";
}

.icon.icon-common.icon-exclamation:before {
  content: "";
}

.icon.icon-common.icon-expand-arrows-alt:before {
  content: "";
}

.icon.icon-common.icon-expand:before {
  content: "u";
}

.icon.icon-common.icon-external-link-alt:before {
  content: "";
}

.icon.icon-common.icon-external-link-square-alt:before {
  content: "";
}

.icon.icon-common.icon-eye-dropper:before {
  content: "";
}

.icon.icon-common.icon-eye-slash:before {
  content: "";
}

.icon.icon-common.icon-eye:before {
  content: "";
}

.icon.icon-common.icon-fast-backward:before {
  content: "";
}

.icon.icon-common.icon-fast-forward:before {
  content: "";
}

.icon.icon-common.icon-female:before {
  content: "";
}

.icon.icon-common.icon-file-signature:before {
  content: "";
}

.icon.icon-common.icon-film:before {
  content: "";
}

.icon.icon-common.icon-filter:before {
  content: "f";
}

.icon.icon-common.icon-fire-extinguisher:before {
  content: "";
}

.icon.icon-common.icon-fire:before {
  content: "";
}

.icon.icon-common.icon-flag-checkered:before {
  content: "";
}

.icon.icon-common.icon-flag:before {
  content: "";
}

.icon.icon-common.icon-flask:before {
  content: "";
}

.icon.icon-common.icon-folder-open:before {
  content: "";
}

.icon.icon-common.icon-folder:before {
  content: "";
}

.icon.icon-common.icon-font:before {
  content: "";
}

.icon.icon-common.icon-football-ball:before {
  content: "";
}

.icon.icon-common.icon-forward:before {
  content: "";
}

.icon.icon-common.icon-futbol:before {
  content: "";
}

.icon.icon-common.icon-gamepad:before {
  content: "";
}

.icon.icon-common.icon-gavel:before {
  content: "";
}

.icon.icon-common.icon-gem:before {
  content: "";
}

.icon.icon-common.icon-genderless:before {
  content: "";
}

.icon.icon-common.icon-gift:before {
  content: "";
}

.icon.icon-common.icon-glass-martini:before {
  content: "";
}

.icon.icon-common.icon-globe:before {
  content: "";
}

.icon.icon-common.icon-golf-ball:before {
  content: "";
}

.icon.icon-common.icon-graduation-cap:before {
  content: "";
}

.icon.icon-common.icon-h-square:before {
  content: "";
}

.icon.icon-common.icon-hand-lizard:before {
  content: "";
}

.icon.icon-common.icon-hand-paper:before {
  content: "";
}

.icon.icon-common.icon-hand-peace:before {
  content: "";
}

.icon.icon-common.icon-hand-point-down:before {
  content: "";
}

.icon.icon-common.icon-hand-point-left:before {
  content: "";
}

.icon.icon-common.icon-hand-point-right:before {
  content: "";
}

.icon.icon-common.icon-hand-point-up:before {
  content: "";
}

.icon.icon-common.icon-hand-pointer:before {
  content: "";
}

.icon.icon-common.icon-hand-rock:before {
  content: "";
}

.icon.icon-common.icon-hand-scissors:before {
  content: "";
}

.icon.icon-common.icon-hand-spock:before {
  content: "";
}

.icon.icon-common.icon-handshake:before {
  content: "";
}

.icon.icon-common.icon-hashtag:before {
  content: "";
}

.icon.icon-common.icon-hdd:before {
  content: "";
}

.icon.icon-common.icon-heading:before {
  content: "";
}

.icon.icon-common.icon-headphones:before {
  content: "";
}

.icon.icon-common.icon-heart:before {
  content: "";
}

.icon.icon-common.icon-heartbeat:before {
  content: "";
}

.icon.icon-common.icon-history:before {
  content: "";
}

.icon.icon-common.icon-home:before {
  content: "";
}

.icon.icon-common.icon-hospital:before {
  content: "";
}

.icon.icon-common.icon-hourglass-end:before {
  content: "";
}

.icon.icon-common.icon-hourglass-half:before {
  content: "";
}

.icon.icon-common.icon-hourglass-start:before {
  content: "";
}

.icon.icon-common.icon-hourglass:before {
  content: "";
}

.icon.icon-common.icon-i-cursor:before {
  content: "";
}

.icon.icon-common.icon-id-badge:before {
  content: "";
}

.icon.icon-common.icon-id-card:before {
  content: "";
}

.icon.icon-common.icon-image:before {
  content: "";
}

.icon.icon-common.icon-images:before {
  content: "";
}

.icon.icon-common.icon-inbox:before {
  content: "";
}

.icon.icon-common.icon-indent:before {
  content: "";
}

.icon.icon-common.icon-industry:before {
  content: "";
}

.icon.icon-common.icon-italic:before {
  content: "";
}

.icon.icon-common.icon-key:before {
  content: "";
}

.icon.icon-common.icon-keyboard:before {
  content: "";
}

.icon.icon-common.icon-language:before {
  content: "";
}

.icon.icon-common.icon-laptop:before {
  content: "";
}

.icon.icon-common.icon-leaf:before {
  content: "";
}

.icon.icon-common.icon-lemon:before {
  content: "";
}

.icon.icon-common.icon-level-down-alt:before {
  content: "";
}

.icon.icon-common.icon-level-up-alt:before {
  content: "";
}

.icon.icon-common.icon-lightbulb:before {
  content: "";
}

.icon.icon-common.icon-link:before {
  content: "";
}

.icon.icon-common.icon-lira-sign:before {
  content: "";
}

.icon.icon-common.icon-list-ol:before {
  content: "";
}

.icon.icon-common.icon-list-ul:before {
  content: "";
}

.icon.icon-common.icon-list:before {
  content: "";
}

.icon.icon-common.icon-location-arrow:before {
  content: "";
}

.icon.icon-common.icon-long-arrow-alt-down:before {
  content: "";
}

.icon.icon-common.icon-long-arrow-alt-left:before {
  content: "";
}

.icon.icon-common.icon-long-arrow-alt-right:before {
  content: "";
}

.icon.icon-common.icon-long-arrow-alt-up:before {
  content: "";
}

.icon.icon-common.icon-low-vision:before {
  content: "";
}

.icon.icon-common.icon-magic:before {
  content: "";
}

.icon.icon-common.icon-magnet:before {
  content: "";
}

.icon.icon-common.icon-male:before {
  content: "";
}

.icon.icon-common.icon-map-pin:before {
  content: "";
}

.icon.icon-common.icon-map-signs:before {
  content: "";
}

.icon.icon-common.icon-mars-double:before {
  content: "";
}

.icon.icon-common.icon-mars-stroke-h:before {
  content: "";
}

.icon.icon-common.icon-mars-stroke-v:before {
  content: "";
}

.icon.icon-common.icon-mars-stroke:before {
  content: "";
}

.icon.icon-common.icon-mars:before {
  content: "";
}

.icon.icon-common.icon-medkit:before {
  content: "";
}

.icon.icon-common.icon-mercury:before {
  content: "";
}

.icon.icon-common.icon-microchip:before {
  content: "";
}

.icon.icon-common.icon-microphone-slash:before {
  content: "";
}

.icon.icon-common.icon-microphone:before {
  content: "";
}

.icon.icon-common.icon-minus-circle:before {
  content: "";
}

.icon.icon-common.icon-minus-square:before {
  content: "";
}

.icon.icon-common.icon-minus:before {
  content: "";
}

.icon.icon-common.icon-mobile-alt:before {
  content: "";
}

.icon.icon-common.icon-mobile:before {
  content: "";
}

.icon.icon-common.icon-money-bill-alt:before {
  content: "";
}

.icon.icon-common.icon-moon:before {
  content: "";
}

.icon.icon-common.icon-motorcycle:before {
  content: "";
}

.icon.icon-common.icon-mouse-pointer:before {
  content: "";
}

.icon.icon-common.icon-music:before {
  content: "";
}

.icon.icon-common.icon-neuter:before {
  content: "";
}

.icon.icon-common.icon-newspaper:before {
  content: "";
}

.icon.icon-common.icon-object-group:before {
  content: "";
}

.icon.icon-common.icon-object-ungroup:before {
  content: "";
}

.icon.icon-common.icon-outdent:before {
  content: "";
}

.icon.icon-common.icon-paint-brush:before {
  content: "";
}

.icon.icon-common.icon-paper-plane:before {
  content: "";
}

.icon.icon-common.icon-paperclip:before {
  content: "";
}

.icon.icon-common.icon-paragraph:before {
  content: "";
}

.icon.icon-common.icon-passport:before {
  content: "";
}

.icon.icon-common.icon-paste:before {
  content: "";
}

.icon.icon-common.icon-pause-circle:before {
  content: "";
}

.icon.icon-common.icon-pause:before {
  content: "";
}

.icon.icon-common.icon-paw:before {
  content: "";
}

.icon.icon-common.icon-pen-square:before {
  content: "";
}

.icon.icon-common.icon-pencil-alt:before {
  content: "";
}

.icon.icon-common.icon-percent:before {
  content: "";
}

.icon.icon-common.icon-phone:before {
  content: "";
}

.icon.icon-common.icon-plane:before {
  content: "";
}

.icon.icon-common.icon-play-circle:before {
  content: "";
}

.icon.icon-common.icon-play:before {
  content: "v";
}

.icon.icon-common.icon-plug:before {
  content: "";
}

.icon.icon-common.icon-plus-circle:before {
  content: "";
}

.icon.icon-common.icon-plus-square:before {
  content: "";
}

.icon.icon-common.icon-plus:before {
  content: "";
}

.icon.icon-common.icon-podcast:before {
  content: "";
}

.icon.icon-common.icon-pound-sign:before {
  content: "";
}

.icon.icon-common.icon-power-off:before {
  content: "";
}

.icon.icon-common.icon-print:before {
  content: "P";
}

.icon.icon-common.icon-puzzle-piece:before {
  content: "";
}

.icon.icon-common.icon-qrcode:before {
  content: "";
}

.icon.icon-common.icon-question-circle:before {
  content: "";
}

.icon.icon-common.icon-question:before {
  content: "";
}

.icon.icon-common.icon-quote-left:before {
  content: "";
}

.icon.icon-common.icon-quote-right:before {
  content: "";
}

.icon.icon-common.icon-random:before {
  content: "";
}

.icon.icon-common.icon-recycle:before {
  content: "";
}

.icon.icon-common.icon-redo-alt:before {
  content: "";
}

.icon.icon-common.icon-redo:before {
  content: "}";
}

.icon.icon-common.icon-reply-all:before {
  content: "";
}

.icon.icon-common.icon-reply:before {
  content: "";
}

.icon.icon-common.icon-retweet:before {
  content: "";
}

.icon.icon-common.icon-road:before {
  content: "";
}

.icon.icon-common.icon-rocket:before {
  content: "";
}

.icon.icon-common.icon-rss:before {
  content: "";
}

.icon.icon-common.icon-ruble-sign:before {
  content: "";
}

.icon.icon-common.icon-rupee-sign:before {
  content: "";
}

.icon.icon-common.icon-search-minus:before {
  content: "";
}

.icon.icon-common.icon-search-plus:before {
  content: "";
}

.icon.icon-common.icon-search:before {
  content: "";
}

.icon.icon-common.icon-server:before {
  content: "";
}

.icon.icon-common.icon-share-alt:before {
  content: "";
}

.icon.icon-common.icon-share-square:before {
  content: "";
}

.icon.icon-common.icon-share:before {
  content: "";
}

.icon.icon-common.icon-shekel-sign:before {
  content: "";
}

.icon.icon-common.icon-shield-alt:before {
  content: "";
}

.icon.icon-common.icon-ship:before {
  content: "";
}

.icon.icon-common.icon-shower:before {
  content: "";
}

.icon.icon-common.icon-sign-in-alt:before {
  content: "";
}

.icon.icon-common.icon-sign-language:before {
  content: "";
}

.icon.icon-common.icon-sign-out-alt:before {
  content: "";
}

.icon.icon-common.icon-signal:before {
  content: "";
}

.icon.icon-common.icon-sitemap:before {
  content: "";
}

.icon.icon-common.icon-sliders-h:before {
  content: "";
}

.icon.icon-common.icon-snowflake:before {
  content: "";
}

.icon.icon-common.icon-sort-alpha-down:before {
  content: "";
}

.icon.icon-common.icon-sort-alpha-up:before {
  content: "";
}

.icon.icon-common.icon-sort-amount-down:before {
  content: "";
}

.icon.icon-common.icon-sort-amount-up:before {
  content: "";
}

.icon.icon-common.icon-sort-down:before {
  content: "";
}

.icon.icon-common.icon-sort-numeric-down:before {
  content: "";
}

.icon.icon-common.icon-sort-numeric-up:before {
  content: "";
}

.icon.icon-common.icon-sort-up:before {
  content: "";
}

.icon.icon-common.icon-sort:before {
  content: "";
}

.icon.icon-common.icon-spinner:before {
  content: "";
}

.icon.icon-common.icon-square-full:before {
  content: "";
}

.icon.icon-common.icon-square:before {
  content: "";
}

.icon.icon-common.icon-star-half:before {
  content: "";
}

.icon.icon-common.icon-star:before {
  content: "";
}

.icon.icon-common.icon-step-backward:before {
  content: "";
}

.icon.icon-common.icon-step-forward:before {
  content: "";
}

.icon.icon-common.icon-stethoscope:before {
  content: "";
}

.icon.icon-common.icon-sticky-note:before {
  content: "";
}

.icon.icon-common.icon-stop-circle:before {
  content: "";
}

.icon.icon-common.icon-stop:before {
  content: "";
}

.icon.icon-common.icon-stopwatch:before {
  content: "";
}

.icon.icon-common.icon-street-view:before {
  content: "";
}

.icon.icon-common.icon-strikethrough:before {
  content: "";
}

.icon.icon-common.icon-subscript:before {
  content: "";
}

.icon.icon-common.icon-subway:before {
  content: "";
}

.icon.icon-common.icon-suitcase:before {
  content: "";
}

.icon.icon-common.icon-sun:before {
  content: "";
}

.icon.icon-common.icon-superscript:before {
  content: "";
}

.icon.icon-common.icon-sync-alt:before {
  content: "";
}

.icon.icon-common.icon-sync:before {
  content: "";
}

.icon.icon-common.icon-table-tennis:before {
  content: "";
}

.icon.icon-common.icon-table:before {
  content: "";
}

.icon.icon-common.icon-tablet-alt:before {
  content: "";
}

.icon.icon-common.icon-tablet:before {
  content: "";
}

.icon.icon-common.icon-tachometer-alt:before {
  content: "";
}

.icon.icon-common.icon-tag:before {
  content: "";
}

.icon.icon-common.icon-tags:before {
  content: "";
}

.icon.icon-common.icon-tasks:before {
  content: "";
}

.icon.icon-common.icon-taxi:before {
  content: "";
}

.icon.icon-common.icon-terminal:before {
  content: "";
}

.icon.icon-common.icon-text-height:before {
  content: "";
}

.icon.icon-common.icon-text-width:before {
  content: "";
}

.icon.icon-common.icon-th-large:before {
  content: "";
}

.icon.icon-common.icon-th-list:before {
  content: "";
}

.icon.icon-common.icon-th:before {
  content: "";
}

.icon.icon-common.icon-thermometer-empty:before {
  content: "";
}

.icon.icon-common.icon-thermometer-full:before {
  content: "";
}

.icon.icon-common.icon-thermometer-half:before {
  content: "";
}

.icon.icon-common.icon-thermometer-quarter:before {
  content: "";
}

.icon.icon-common.icon-thermometer-three-quarters:before {
  content: "";
}

.icon.icon-common.icon-thumbs-down:before {
  content: "";
}

.icon.icon-common.icon-thumbs-up:before {
  content: "";
}

.icon.icon-common.icon-thumbtack:before {
  content: "";
}

.icon.icon-common.icon-ticket-alt:before {
  content: "";
}

.icon.icon-common.icon-times-circle:before {
  content: "";
}

.icon.icon-common.icon-times:before {
  content: "";
}

.icon.icon-common.icon-tint:before {
  content: "";
}

.icon.icon-common.icon-toggle-off:before {
  content: "";
}

.icon.icon-common.icon-toggle-on:before {
  content: "";
}

.icon.icon-common.icon-trademark:before {
  content: "";
}

.icon.icon-common.icon-train:before {
  content: "";
}

.icon.icon-common.icon-transgender-alt:before {
  content: "";
}

.icon.icon-common.icon-transgender:before {
  content: "";
}

.icon.icon-common.icon-trash-alt:before {
  content: "";
}

.icon.icon-common.icon-trash:before {
  content: "";
}

.icon.icon-common.icon-tree:before {
  content: "";
}

.icon.icon-common.icon-trophy:before {
  content: "";
}

.icon.icon-common.icon-truck:before {
  content: "";
}

.icon.icon-common.icon-tty:before {
  content: "";
}

.icon.icon-common.icon-tv:before {
  content: "";
}

.icon.icon-common.icon-umbrella:before {
  content: "";
}

.icon.icon-common.icon-underline:before {
  content: "";
}

.icon.icon-common.icon-undo-alt:before {
  content: "";
}

.icon.icon-common.icon-undo:before {
  content: "";
}

.icon.icon-common.icon-universal-access:before {
  content: "";
}

.icon.icon-common.icon-university:before {
  content: "";
}

.icon.icon-common.icon-unlink:before {
  content: "";
}

.icon.icon-common.icon-unlock-alt:before {
  content: "";
}

.icon.icon-common.icon-unlock:before {
  content: "";
}

.icon.icon-common.icon-upload:before {
  content: "";
}

.icon.icon-common.icon-user-circle:before {
  content: "";
}

.icon.icon-common.icon-user-md:before {
  content: "";
}

.icon.icon-common.icon-user-plus:before {
  content: "";
}

.icon.icon-common.icon-user-secret:before {
  content: "";
}

.icon.icon-common.icon-user-times:before {
  content: "";
}

.icon.icon-common.icon-user:before {
  content: "";
}

.icon.icon-common.icon-users:before {
  content: "";
}

.icon.icon-common.icon-utensil-spoon:before {
  content: "";
}

.icon.icon-common.icon-utensils:before {
  content: "";
}

.icon.icon-common.icon-venus-double:before {
  content: "";
}

.icon.icon-common.icon-venus-mars:before {
  content: "";
}

.icon.icon-common.icon-venus:before {
  content: "";
}

.icon.icon-common.icon-video:before {
  content: "";
}

.icon.icon-common.icon-volleyball-ball:before {
  content: "";
}

.icon.icon-common.icon-volume-down:before {
  content: "";
}

.icon.icon-common.icon-volume-off:before {
  content: "";
}

.icon.icon-common.icon-volume-up:before {
  content: "";
}

.icon.icon-common.icon-wheelchair:before {
  content: "";
}

.icon.icon-common.icon-wifi:before {
  content: "";
}

.icon.icon-common.icon-window-close:before {
  content: "";
}

.icon.icon-common.icon-window-maximize:before {
  content: "";
}

.icon.icon-common.icon-window-minimize:before {
  content: "";
}

.icon.icon-common.icon-window-restore:before {
  content: "";
}

.icon.icon-common.icon-won-sign:before {
  content: "";
}

.icon.icon-common.icon-wrench:before {
  content: "";
}

.icon.icon-common.icon-yen-sign:before {
  content: "";
}

.icon.icon-common.icon-add-job:before {
  content: ")";
}

.icon.icon-common.icon-align:before {
  content: "i";
}

.icon.icon-common.icon-analyse-graph:before {
  content: "z";
}

.icon.icon-common.icon-analyse-percent_0:before {
  content: "B";
}

.icon.icon-common.icon-analyse-percent_100:before {
  content: "E";
}

.icon.icon-common.icon-analyse-percent_40:before {
  content: "C";
}

.icon.icon-common.icon-analyse:before {
  content: "A";
}

.icon.icon-common.icon-approved-job:before {
  content: "(";
}

.icon.icon-common.icon-attach:before {
  content: "a";
}

.icon.icon-common.icon-browse:before {
  content: "b";
}

.icon.icon-common.icon-close:before {
  content: "x";
}

.icon.icon-common.icon-collapse-closed:before {
  content: "9";
}

.icon.icon-common.icon-collapse-open:before {
  content: "8";
}

.icon.icon-common.icon-collapse:before {
  content: "w";
}

.icon.icon-common.icon-compare:before {
  content: "O";
}

.icon.icon-common.icon-database-submit:before {
  content: "D";
}

.icon.icon-common.icon-down-page:before {
  content: "⬇";
}

.icon.icon-common.icon-download-alt:before {
  content: "=";
}

.icon.icon-common.icon-edit-user:before {
  content: "5";
}

.icon.icon-common.icon-first-page:before {
  content: "[";
}

.icon.icon-common.icon-fullscreen-collapse:before {
  content: "G";
}

.icon.icon-common.icon-fullscreen:before {
  content: "F";
}

.icon.icon-common.icon-hierarchy:before {
  content: "h";
}

.icon.icon-common.icon-last-page:before {
  content: "]";
}

.icon.icon-common.icon-lock:before {
  content: "L";
}

.icon.icon-common.icon-login:before {
  content: "l";
}

.icon.icon-common.icon-mapping:before {
  content: "0";
}

.icon.icon-common.icon-move:before {
  content: "m";
}

.icon.icon-common.icon-next-page:before {
  content: ">";
}

.icon.icon-common.icon-previous-page:before {
  content: "<";
}

.icon.icon-common.icon-remove:before {
  content: "-";
}

.icon.icon-common.icon-save:before {
  content: "S";
}

.icon.icon-common.icon-scaleable:before {
  content: "Y";
}

.icon.icon-common.icon-search-document:before {
  content: "j";
}

.icon.icon-common.icon-send:before {
  content: "n";
}

.icon.icon-common.icon-submit:before {
  content: "_";
}

.icon.icon-common.icon-target:before {
  content: "";
}

.icon.icon-common.icon-tool:before {
  content: "t";
}

.icon.icon-common.icon-unassigned-job:before {
  content: "*";
}

.icon.icon-common.icon-up-page:before {
  content: "⬆";
}

.icon.icon-common.icon-accommodation:before {
  content: "";
}

.icon.icon-common.icon-alumni:before {
  content: "";
}

.icon.icon-common.icon-announcement:before {
  content: "";
}

.icon.icon-common.icon-basket:before {
  content: "";
}

.icon.icon-common.icon-beta:before {
  content: "";
}

.icon.icon-common.icon-bike:before {
  content: "";
}

.icon.icon-common.icon-biotech:before {
  content: "";
}

.icon.icon-common.icon-bus:before {
  content: "";
}

.icon.icon-common.icon-camera:before {
  content: "";
}

.icon.icon-common.icon-car:before {
  content: "";
}

.icon.icon-common.icon-careers:before {
  content: "";
}

.icon.icon-common.icon-classification:before {
  content: "";
}

.icon.icon-common.icon-contact:before {
  content: "";
}

.icon.icon-common.icon-crosslink:before {
  content: "";
}

.icon.icon-common.icon-discuss:before {
  content: "";
}

.icon.icon-common.icon-documentation:before {
  content: "";
}

.icon.icon-common.icon-drug:before {
  content: "";
}

.icon.icon-common.icon-elixir:before {
  content: "";
}

.icon.icon-common.icon-embl:before {
  content: "";
}

.icon.icon-common.icon-external-systems:before {
  content: "";
}

.icon.icon-common.icon-face-to-face:before {
  content: "";
}

.icon.icon-common.icon-funding:before {
  content: "";
}

.icon.icon-common.icon-group:before {
  content: "";
}

.icon.icon-common.icon-gtls:before {
  content: "";
}

.icon.icon-common.icon-health:before {
  content: "";
}

.icon.icon-common.icon-info:before {
  content: "";
}

.icon.icon-common.icon-location:before {
  content: "";
}

.icon.icon-common.icon-mailing-list:before {
  content: "";
}

.icon.icon-common.icon-math:before {
  content: "";
}

.icon.icon-common.icon-meet-us:before {
  content: "";
}

.icon.icon-common.icon-new:before {
  content: "";
}

.icon.icon-common.icon-newcomers:before {
  content: "";
}

.icon.icon-common.icon-nutraceuticals:before {
  content: "";
}

.icon.icon-common.icon-open-day:before {
  content: "";
}

.icon.icon-common.icon-publication:before {
  content: "";
}

.icon.icon-common.icon-research:before {
  content: "";
}

.icon.icon-common.icon-resource:before {
  content: "";
}

.icon.icon-common.icon-reviewed-data:before {
  content: "";
}

.icon.icon-common.icon-services:before {
  content: "";
}

.icon.icon-common.icon-steps:before {
  content: "";
}

.icon.icon-common.icon-support:before {
  content: "";
}

.icon.icon-common.icon-systems:before {
  content: "";
}

.icon.icon-common.icon-test:before {
  content: "";
}

.icon.icon-common.icon-text-mining:before {
  content: "";
}

.icon.icon-common.icon-toolkit:before {
  content: "";
}

.icon.icon-common.icon-training:before {
  content: "";
}

.icon.icon-common.icon-tutorial:before {
  content: "";
}

.icon.icon-common.icon-unreviewed-data:before {
  content: "";
}

.icon.icon-common.icon-walk:before {
  content: "";
}

.icon.icon-common.icon-android:before {
  content: "";
}

.icon.icon-common.icon-apple:before {
  content: "";
}

.icon.icon-common.icon-blogger:before {
  content: "";
}

.icon.icon-common.icon-facebook:before {
  content: "";
}

.icon.icon-common.icon-flickr:before {
  content: "";
}

.icon.icon-common.icon-github:before {
  content: "";
}

.icon.icon-common.icon-googleplus:before {
  content: "";
}

.icon.icon-common.icon-linkedin:before {
  content: "";
}

.icon.icon-common.icon-linux:before {
  content: "";
}

.icon.icon-common.icon-orcid:before {
  content: "";
}

.icon.icon-common.icon-skype:before {
  content: "";
}

.icon.icon-common.icon-slideshare:before {
  content: "";
}

.icon.icon-common.icon-twitter:before {
  content: "";
}

.icon.icon-common.icon-vimeo:before {
  content: "";
}

.icon.icon-common.icon-weibo:before {
  content: "";
}

.icon.icon-common.icon-windows:before {
  content: "";
}

.icon.icon-common.icon-wordpress:before {
  content: "";
}

.icon.icon-common.icon-youtube:before {
  content: "";
}

.icon.icon-conceptual.icon-chemical:before {
  content: "b";
}

.icon.icon-conceptual.icon-cross-domain:before {
  content: "c";
}

.icon.icon-conceptual.icon-dna:before {
  content: "d";
}

.icon.icon-conceptual.icon-expression:before {
  content: "g";
}

.icon.icon-conceptual.icon-literature:before {
  content: "l";
}

.icon.icon-conceptual.icon-ontology:before {
  content: "o";
}

.icon.icon-conceptual.icon-proteins:before {
  content: "P";
}

.icon.icon-conceptual.icon-structures-3d:before {
  content: "S";
}

.icon.icon-conceptual.icon-structures:before {
  content: "s";
}

.icon.icon-conceptual.icon-systems:before {
  content: "y";
}

.icon.icon-fileformats.icon-AGP:before {
  content: "A";
}

.icon.icon-fileformats.icon-BAM:before {
  content: "B";
}

.icon.icon-fileformats.icon-CML:before {
  content: "C";
}

.icon.icon-fileformats.icon-CRAM:before {
  content: "R";
}

.icon.icon-fileformats.icon-CSV:before {
  content: "c";
}

.icon.icon-fileformats.icon-DOC:before {
  content: "d";
}

.icon.icon-fileformats.icon-FASTA:before {
  content: "a";
}

.icon.icon-fileformats.icon-FASTQ:before {
  content: "f";
}

.icon.icon-fileformats.icon-FLAT:before {
  content: "F";
}

.icon.icon-fileformats.icon-GFF:before {
  content: "G";
}

.icon.icon-fileformats.icon-IMG:before {
  content: "I";
}

.icon.icon-fileformats.icon-JSON:before {
  content: "J";
}

.icon.icon-fileformats.icon-MDL:before {
  content: "M";
}

.icon.icon-fileformats.icon-OBO:before {
  content: "O";
}

.icon.icon-fileformats.icon-OWL:before {
  content: "o";
}

.icon.icon-fileformats.icon-PDF:before {
  content: "p";
}

.icon.icon-fileformats.icon-Postscript:before {
  content: "3";
}

.icon.icon-fileformats.icon-PSI-MI_JSON:before {
  content: "";
}

.icon.icon-fileformats.icon-PSI-MI_TAB:before {
  content: "2";
}

.icon.icon-fileformats.icon-PSI-MI_XML:before {
  content: "1";
}

.icon.icon-fileformats.icon-RD:before {
  content: "r";
}

.icon.icon-fileformats.icon-RDF_XML:before {
  content: "D";
}

.icon.icon-fileformats.icon-RXN:before {
  content: "X";
}

.icon.icon-fileformats.icon-SDF:before {
  content: "s";
}

.icon.icon-fileformats.icon-TSV:before {
  content: "v";
}

.icon.icon-fileformats.icon-TXT:before {
  content: "t";
}

.icon.icon-fileformats.icon-XML:before {
  content: "x";
}

.icon.icon-fileformats.icon-ZIP:before {
  content: "Z";
}

.icon.icon-functional.icon-add-job:before {
  content: ")";
}

.icon.icon-functional.icon-add-user:before {
  content: "7";
}

.icon.icon-functional.icon-add:before {
  content: "+";
}

.icon.icon-functional.icon-align:before {
  content: "i";
}

.icon.icon-functional.icon-analyse-graph:before {
  content: "z";
}

.icon.icon-functional.icon-analyse-percent_0:before {
  content: "B";
}

.icon.icon-functional.icon-analyse-percent_100:before {
  content: "E";
}

.icon.icon-functional.icon-analyse-percent_40:before {
  content: "C";
}

.icon.icon-functional.icon-analyse:before {
  content: "A";
}

.icon.icon-functional.icon-approve:before {
  content: "/";
}

.icon.icon-functional.icon-approved-job:before {
  content: "(";
}

.icon.icon-functional.icon-attach:before {
  content: "a";
}

.icon.icon-functional.icon-browse:before {
  content: "b";
}

.icon.icon-functional.icon-close:before {
  content: "x";
}

.icon.icon-functional.icon-collapse-closed:before {
  content: "9";
}

.icon.icon-functional.icon-collapse-open:before {
  content: "8";
}

.icon.icon-functional.icon-collapse:before {
  content: "w";
}

.icon.icon-functional.icon-compare:before {
  content: "O";
}

.icon.icon-functional.icon-crop:before {
  content: "\\";
}

.icon.icon-functional.icon-cut:before {
  content: "c";
}

.icon.icon-functional.icon-database-submit:before {
  content: "D";
}

.icon.icon-functional.icon-delete:before {
  content: "d";
}

.icon.icon-functional.icon-down-page:before {
  content: "⬇";
}

.icon.icon-functional.icon-download:before {
  content: "=";
}

.icon.icon-functional.icon-edit-user:before {
  content: "5";
}

.icon.icon-functional.icon-edit:before {
  content: "e";
}

.icon.icon-functional.icon-expand:before {
  content: "u";
}

.icon.icon-functional.icon-filter:before {
  content: "f";
}

.icon.icon-functional.icon-first-page:before {
  content: "[";
}

.icon.icon-functional.icon-fullscreen:before {
  content: "F";
}

.icon.icon-functional.icon-hierarchy:before {
  content: "h";
}

.icon.icon-functional.icon-last-page:before {
  content: "]";
}

.icon.icon-functional.icon-like:before {
  content: "k";
}

.icon.icon-functional.icon-lock:before {
  content: "L";
}

.icon.icon-functional.icon-login:before {
  content: "l";
}

.icon.icon-functional.icon-mapping:before {
  content: "0";
}

.icon.icon-functional.icon-menu:before {
  content: "M";
}

.icon.icon-functional.icon-move:before {
  content: "m";
}

.icon.icon-functional.icon-next-page:before {
  content: ">";
}

.icon.icon-functional.icon-play:before {
  content: "v";
}

.icon.icon-functional.icon-previous-page:before {
  content: "<";
}

.icon.icon-functional.icon-print:before {
  content: "P";
}

.icon.icon-functional.icon-redo:before {
  content: "}";
}

.icon.icon-functional.icon-refresh:before {
  content: "R";
}

.icon.icon-functional.icon-remove-user:before {
  content: "6";
}

.icon.icon-functional.icon-remove:before {
  content: "-";
}

.icon.icon-functional.icon-save:before {
  content: "S";
}

.icon.icon-functional.icon-scaleable:before {
  content: "Y";
}

.icon.icon-functional.icon-search-document:before {
  content: "j";
}

.icon.icon-functional.icon-search:before {
  content: "1";
}

.icon.icon-functional.icon-send:before {
  content: "n";
}

.icon.icon-functional.icon-settings:before {
  content: "s";
}

.icon.icon-functional.icon-share:before {
  content: "r";
}

.icon.icon-functional.icon-stop:before {
  content: "o";
}

.icon.icon-functional.icon-submit:before {
  content: "_";
}

.icon.icon-functional.icon-target:before {
  content: "T";
}

.icon.icon-functional.icon-tool:before {
  content: "t";
}

.icon.icon-functional.icon-unassigned-job:before {
  content: "*";
}

.icon.icon-functional.icon-undo:before {
  content: "{";
}

.icon.icon-functional.icon-unlock:before {
  content: "U";
}

.icon.icon-functional.icon-up-page:before {
  content: "⬆";
}

.icon.icon-functional.icon-view:before {
  content: "4";
}

.icon.icon-functional.icon-zoom-in:before {
  content: "3";
}

.icon.icon-functional.icon-zoom-out:before {
  content: "2";
}

.icon.icon-generic.icon-accommodation:before {
  content: "a";
}

.icon.icon-generic.icon-alert:before {
  content: "l";
}

.icon.icon-generic.icon-alumni:before {
  content: "A";
}

.icon.icon-generic.icon-announcement:before {
  content: "U";
}

.icon.icon-generic.icon-basket:before {
  content: "b";
}

.icon.icon-generic.icon-beta:before {
  content: ">";
}

.icon.icon-generic.icon-bike:before {
  content: "3";
}

.icon.icon-generic.icon-biotech:before {
  content: "B";
}

.icon.icon-generic.icon-bus:before {
  content: "1";
}

.icon.icon-generic.icon-calendar:before {
  content: "r";
}

.icon.icon-generic.icon-camera:before {
  content: "!";
}

.icon.icon-generic.icon-car:before {
  content: "2";
}

.icon.icon-generic.icon-careers:before {
  content: "c";
}

.icon.icon-generic.icon-classification:before {
  content: "e";
}

.icon.icon-generic.icon-clock:before {
  content: "{";
}

.icon.icon-generic.icon-contact:before {
  content: "C";
}

.icon.icon-generic.icon-crosslink:before {
  content: "d";
}

.icon.icon-generic.icon-database:before {
  content: "D";
}

.icon.icon-generic.icon-discuss:before {
  content: "\\";
}

.icon.icon-generic.icon-documentation:before {
  content: ";";
}

.icon.icon-generic.icon-drug:before {
  content: "u";
}

.icon.icon-generic.icon-elixir:before {
  content: "%";
}

.icon.icon-generic.icon-email:before {
  content: "E";
}

.icon.icon-generic.icon-embl:before {
  content: "&";
}

.icon.icon-generic.icon-external-link:before {
  content: "x";
}

.icon.icon-generic.icon-external-systems:before {
  content: "y";
}

.icon.icon-generic.icon-face-to-face:before {
  content: "f";
}

.icon.icon-generic.icon-find-us:before {
  content: "]";
}

.icon.icon-generic.icon-funding:before {
  content: "F";
}

.icon.icon-generic.icon-graph:before {
  content: "g";
}

.icon.icon-generic.icon-group:before {
  content: "}";
}

.icon.icon-generic.icon-gtls:before {
  content: "G";
}

.icon.icon-generic.icon-health:before {
  content: "h";
}

.icon.icon-generic.icon-help:before {
  content: "?";
}

.icon.icon-generic.icon-home:before {
  content: "H";
}

.icon.icon-generic.icon-industry:before {
  content: "I";
}

.icon.icon-generic.icon-info:before {
  content: "i";
}

.icon.icon-generic.icon-link:before {
  content: "L";
}

.icon.icon-generic.icon-location:before {
  content: "[";
}

.icon.icon-generic.icon-lovedata:before {
  content: "o";
}

.icon.icon-generic.icon-mailing-list:before {
  content: "m";
}

.icon.icon-generic.icon-math:before {
  content: "v";
}

.icon.icon-generic.icon-meet-us:before {
  content: "M";
}

.icon.icon-generic.icon-mobile-device:before {
  content: "/";
}

.icon.icon-generic.icon-new:before {
  content: "<";
}

.icon.icon-generic.icon-newcomers:before {
  content: "n";
}

.icon.icon-generic.icon-news:before {
  content: "N";
}

.icon.icon-generic.icon-nutraceuticals:before {
  content: "\"";
}

.icon.icon-generic.icon-open-day:before {
  content: "O";
}

.icon.icon-generic.icon-piechart:before {
  content: "p";
}

.icon.icon-generic.icon-plane:before {
  content: "4";
}

.icon.icon-generic.icon-publication:before {
  content: "P";
}

.icon.icon-generic.icon-research:before {
  content: ")";
}

.icon.icon-generic.icon-resource:before {
  content: "R";
}

.icon.icon-generic.icon-reviewed-data:before {
  content: "q";
}

.icon.icon-generic.icon-services:before {
  content: "(";
}

.icon.icon-generic.icon-steps:before {
  content: "#";
}

.icon.icon-generic.icon-support:before {
  content: "s";
}

.icon.icon-generic.icon-systems:before {
  content: "S";
}

.icon.icon-generic.icon-terms:before {
  content: "";
}

.icon.icon-generic.icon-test:before {
  content: "0";
}

.icon.icon-generic.icon-text-mining:before {
  content: "X";
}

.icon.icon-generic.icon-toolkit:before {
  content: ":";
}

.icon.icon-generic.icon-train:before {
  content: "7";
}

.icon.icon-generic.icon-training:before {
  content: "T";
}

.icon.icon-generic.icon-tutorial:before {
  content: "t";
}

.icon.icon-generic.icon-unreviewed-data:before {
  content: "Q";
}

.icon.icon-generic.icon-video:before {
  content: "V";
}

.icon.icon-generic.icon-walk:before {
  content: "6";
}

.icon.icon-socialmedia.icon-android:before {
  content: "a";
}

.icon.icon-socialmedia.icon-apple:before {
  content: "A";
}

.icon.icon-socialmedia.icon-blogger:before {
  content: "B";
}

.icon.icon-socialmedia.icon-facebook:before {
  content: "F";
}

.icon.icon-socialmedia.icon-flickr:before {
  content: "f";
}

.icon.icon-socialmedia.icon-github:before {
  content: "g";
}

.icon.icon-socialmedia.icon-googleplus:before {
  content: "G";
}

.icon.icon-socialmedia.icon-linkedin:before {
  content: "L";
}

.icon.icon-socialmedia.icon-linux:before {
  content: "X";
}

.icon.icon-socialmedia.icon-orcid:before {
  content: "O";
}

.icon.icon-socialmedia.icon-rss:before {
  content: "R";
}

.icon.icon-socialmedia.icon-skype:before {
  content: "s";
}

.icon.icon-socialmedia.icon-slideshare:before {
  content: "S";
}

.icon.icon-socialmedia.icon-twitter:before {
  content: "T";
}

.icon.icon-socialmedia.icon-vimeo:before {
  content: "V";
}

.icon.icon-socialmedia.icon-weibo:before {
  content: "W";
}

.icon.icon-socialmedia.icon-windows:before {
  content: "w";
}

.icon.icon-socialmedia.icon-wordpress:before {
  content: "P";
}

.icon.icon-socialmedia.icon-youtube:before {
  content: "Y";
}

.icon.icon-species.icon-alpaca:before {
  content: "a";
}

.icon.icon-species.icon-amoeba:before {
  content: "0";
}

.icon.icon-species.icon-anolis:before {
  content: "7";
}

.icon.icon-species.icon-armadillo:before {
  content: "l";
}

.icon.icon-species.icon-aspergillus:before {
  content: "£";
}

.icon.icon-species.icon-barley:before {
  content: "5";
}

.icon.icon-species.icon-bat:before {
  content: "(";
}

.icon.icon-species.icon-bee:before {
  content: "$";
}

.icon.icon-species.icon-brachypodium:before {
  content: "%";
}

.icon.icon-species.icon-brassica:before {
  content: "B";
}

.icon.icon-species.icon-bug:before {
  content: "b";
}

.icon.icon-species.icon-c-elegans:before {
  content: "W";
}

.icon.icon-species.icon-cat:before {
  content: "A";
}

.icon.icon-species.icon-chicken:before {
  content: "k";
}

.icon.icon-species.icon-chimpanzee:before {
  content: "i";
}

.icon.icon-species.icon-corn:before {
  content: "c";
}

.icon.icon-species.icon-cow:before {
  content: "C";
}

.icon.icon-species.icon-diatom:before {
  content: "2";
}

.icon.icon-species.icon-dog:before {
  content: "d";
}

.icon.icon-species.icon-dolphin:before {
  content: "D";
}

.icon.icon-species.icon-ecoli:before {
  content: "L";
}

.icon.icon-species.icon-elephant:before {
  content: "e";
}

.icon.icon-species.icon-ferret:before {
  content: "!";
}

.icon.icon-species.icon-finch:before {
  content: "n";
}

.icon.icon-species.icon-fly:before {
  content: "F";
}

.icon.icon-species.icon-frog:before {
  content: "f";
}

.icon.icon-species.icon-fungus:before {
  content: "u";
}

.icon.icon-species.icon-glycinemax:before {
  content: "^";
}

.icon.icon-species.icon-goat:before {
  content: "m";
}

.icon.icon-species.icon-gorilla:before {
  content: "G";
}

.icon.icon-species.icon-grapes:before {
  content: "O";
}

.icon.icon-species.icon-guinea-pig:before {
  content: "g";
}

.icon.icon-species.icon-hedgehog:before {
  content: "o";
}

.icon.icon-species.icon-horse:before {
  content: "h";
}

.icon.icon-species.icon-human:before {
  content: "H";
}

.icon.icon-species.icon-kangaroo-rat:before {
  content: "3";
}

.icon.icon-species.icon-louse:before {
  content: "4";
}

.icon.icon-species.icon-marmoset:before {
  content: "q";
}

.icon.icon-species.icon-monkey:before {
  content: "r";
}

.icon.icon-species.icon-monodelphis:before {
  content: "9";
}

.icon.icon-species.icon-mosquito:before {
  content: "1";
}

.icon.icon-species.icon-mouse-lemur:before {
  content: "N";
}

.icon.icon-species.icon-mouse:before {
  content: "M";
}

.icon.icon-species.icon-orangutan:before {
  content: "*";
}

.icon.icon-species.icon-papio:before {
  content: "8";
}

.icon.icon-species.icon-pig:before {
  content: "p";
}

.icon.icon-species.icon-plant:before {
  content: "P";
}

.icon.icon-species.icon-plasmodium:before {
  content: "@";
}

.icon.icon-species.icon-platypus:before {
  content: "U";
}

.icon.icon-species.icon-pufferfish:before {
  content: "E";
}

.icon.icon-species.icon-rabbit:before {
  content: "t";
}

.icon.icon-species.icon-rat:before {
  content: "R";
}

.icon.icon-species.icon-ray:before {
  content: "+";
}

.icon.icon-species.icon-rice:before {
  content: "6";
}

.icon.icon-species.icon-scorpion:before {
  content: "s";
}

.icon.icon-species.icon-sheep:before {
  content: "x";
}

.icon.icon-species.icon-shrew:before {
  content: "Q";
}

.icon.icon-species.icon-snail:before {
  content: "'";
}

.icon.icon-species.icon-spider:before {
  content: "S";
}

.icon.icon-species.icon-squirrel:before {
  content: "I";
}

.icon.icon-species.icon-tick:before {
  content: "&";
}

.icon.icon-species.icon-tomatoes:before {
  content: ")";
}

.icon.icon-species.icon-virus:before {
  content: "v";
}

.icon.icon-species.icon-wallaby:before {
  content: "w";
}

.icon.icon-species.icon-yeast:before {
  content: "Y";
}

.icon.icon-species.icon-zebrafish:before {
  content: "Z";
}

@media speech {
  .icon.icon-common:not([aria-label]), .icon.icon-socialmedia:not([aria-label]), .icon.icon-species:not([aria-label]), .icon.icon-generic:not([aria-label]), .icon.icon-fileformats:not([aria-label]), .icon.icon-functional:not([aria-label]), .icon.icon-chemistry:not([aria-label]) {
    display: none;
  }
}
/*# sourceMappingURL=index.57939e88.css.map */
